import React from "react"
import Video1 from "./Video1"
import Video4 from "./Video4"

const Videos = ({ block, ...props }) => {
  switch (block.videos.length) {
    case 1:
      return (
        <Video1
          titre={block.titre}
          id01={block.videos[0].urlYoutube.providerUid}
          description={block.description}
          videoFirst={block.videoDAbord}
        />
      )
    case 2:
      return (
        <Video4
          titre={block.titre}
          description={block.description}
          id01={block.videos[0].urlYoutube.providerUid}
          id02={block.videos[1].urlYoutube.providerUid}
        />
      )
    case 3:
      return (
        <Video4
          titre={block.titre}
          description={block.description}
          id01={block.videos[0].urlYoutube.providerUid}
          id02={block.videos[1].urlYoutube.providerUid}
          id03={block.videos[2].urlYoutube.providerUid}
        />
      )
    case 4:
      return (
        <Video4
          titre={block.titre}
          description={block.description}
          id01={block.videos[0].urlYoutube.providerUid}
          id02={block.videos[1].urlYoutube.providerUid}
          id03={block.videos[2].urlYoutube.providerUid}
          id04={block.videos[3].urlYoutube.providerUid}
        />
      )
    default:
      return null
  }
}

Videos.propTypes = {}

export default Videos
