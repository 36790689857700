import React from "react"

const TitreVideo = ({ children, ...props }) => {
  return (
    <h3 className="text-visuall-video font-archia font-bold text-5xl mt-12">
      {children}
    </h3>
  )
}

export default TitreVideo
