import React from "react"
const DescriptionVideo = ({ children, ...props }) => {
  return (
    <p className="font-display text-xl text-visuall-gris-logo leading-relaxed mt-8">
      {children}
    </p>
  )
}

export default DescriptionVideo
