import React from "react"
import YouTube from "../../utils/YouTube"
import TitreVideo from "./TitreVideo"
import Titre2Video from "./Titre2Video"
import DescriptionVideo from "./DescriptionVideo"

const Video4 = ({
  id01 = "tnkOJ9_u030",
  id02 = "tnkOJ9_u030",
  id03 = "tnkOJ9_u030",
  id04 = "tnkOJ9_u030",
  titre,
  titre2,
  description,
  ...props
}) => {
  return (
    <article className="container mx-auto mt-12">
      <div className="grid lg:grid-cols-3 gap-4">
        <div className="px-2 lg:px-0 lg:pr-12">
          <TitreVideo>{titre}</TitreVideo>
          <Titre2Video>{titre2}</Titre2Video>
          <DescriptionVideo>{description}</DescriptionVideo>
        </div>
        <div className="lg:col-span-2">
          <YouTube videoId={id01} className="w-full aspect-video" />
        </div>
        <div>
          <YouTube videoId={id02} className="w-full aspect-video" />
        </div>
        <div>
          <YouTube videoId={id03} className="w-full aspect-video" />
        </div>
        <div>
          <YouTube videoId={id04} className="w-full aspect-video" />
        </div>
      </div>
    </article>
  )
}

Video4.propTypes = {}

export default Video4
