import React, { useContext, useReducer, useRef } from "react"
import { useInView } from "react-intersection-observer"
import { CookieSocialConsent } from "../GDPR/cookies"
import { CookiesContext } from "../GDPR/CookieContext"

const YouTube = ({ videoId, className, iframeClass }) => {
  const { cookies, dispatch } = useContext(CookiesContext)
  const videoContainer = useRef(null)
  const onScreen = useInView(videoContainer)

  if (!videoId) {
    return false
  }

  return (
    <>
      <div ref={videoContainer} className={"w-full h-full"}>
        {!cookies.social && (
          <CookieSocialConsent cookies={cookies} dispatch={dispatch} />
        )}
        {onScreen && cookies.social && (
          <iframe
            src={`https://www.youtube-nocookie.com/embed/${videoId}`}
            title={"videoTitle"}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
            className="w-full aspect-video"
          />
        )}
        {!onScreen && (
          <img
            className={"object-cover"}
            src={`https://i.ytimg.com/vi/${videoId}/hqdefault.jpg`}
            alt={"Découvrez Visuall"}
          />
        )}
      </div>
    </>
  )
}
export default YouTube
