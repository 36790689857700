import React from "react"
import classNames from "classnames"
import TitreVideo from "./TitreVideo"
import Titre2Video from "./Titre2Video"
import DescriptionVideo from "./DescriptionVideo"
import YouTube from "../../utils/YouTube"

const Video1 = ({
  id01 = "tnkOJ9_u030",
  id02,
  titre,
  titre2,
  description,
  videoFirst = true,
  ...props
}) => {
  const videoClasses = classNames("col-span-2", {
    "lg:order-1": videoFirst === true,
    "lg:order-2": videoFirst === false || undefined,
  })
  const titreColClasses = classNames({
    "lg:order-2": videoFirst === true,
    "lg:order-1": videoFirst === false || undefined,
  })
  const titreClasses = classNames("px-2 lg:px-0", {
    "lg:pl-12": videoFirst === true,
    "lg:pr-12": videoFirst === false || undefined,
  })
  return (
    <article className="container mx-auto mt-12">
      <div className="lg:grid lg:grid-cols-3 gap-4">
        <div className={videoClasses}>
          <YouTube videoId={id01} className="w-full h-full aspect-video" />
        </div>
        <div className={titreColClasses}>
          <div className={titreClasses}>
            <TitreVideo>{titre}</TitreVideo>
            <Titre2Video>{titre2}</Titre2Video>
            {description && <DescriptionVideo>{description}</DescriptionVideo>}
          </div>
          {id02 && (
            <div className={"mt-4"}>
              <YouTube videoId={id02} className="w-full aspect-video" />
            </div>
          )}
        </div>
      </div>
    </article>
  )
}

Video1.propTypes = {}

export default Video1
