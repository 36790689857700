import React from "react"

const Titre2Video = ({ children, ...props }) => {
  return (
    <h3 className="font-archia font-light text-visuall-gris-logo text-4xl mt-4">
      {children}
    </h3>
  )
}

export default Titre2Video
